import { template as template_2f6824797d034fd5ba5464e54c4b60e6 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_2f6824797d034fd5ba5464e54c4b60e6(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
