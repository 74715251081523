import { template as template_4cd20533ab444ee29a356d74e5a0bb7f } from "@ember/template-compiler";
const WelcomeHeader = template_4cd20533ab444ee29a356d74e5a0bb7f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
