import { template as template_dc552dab114f413bb78e7b4cda62b8b8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_dc552dab114f413bb78e7b4cda62b8b8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
