import { template as template_0d520a2fa92a42899fc4593a3ec8d0e1 } from "@ember/template-compiler";
import { and } from "truth-helpers";
import ItemRepliesCell from "discourse/components/topic-list/item/replies-cell";
import TopicPostBadges from "discourse/components/topic-post-badges";
const PostCountOrBadges = template_0d520a2fa92a42899fc4593a3ec8d0e1(`
  {{#if (and @postBadgesEnabled @topic.unread_posts)}}
    <TopicPostBadges
      @unreadPosts={{@topic.unread_posts}}
      @unseen={{@topic.unseen}}
      @url={{@topic.lastUnreadUrl}}
    />
  {{else}}
    <ItemRepliesCell @topic={{@topic}} @tagName="div" />
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostCountOrBadges;
